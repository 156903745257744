import { Divider, Grid, Paper, Typography, Tooltip } from "@material-ui/core";
import React from "react";
import { formatCurrency } from './formatter';

export function Investment({data, classes}) {
  const {
    total, coins, rewards, ETHUSD, USDCAD, RVNUSD, cashInWallet, ethInWallet, btcInWallet, cfxInWallet, investmentCad,
    usdtInWallet, busdInWallet, usdcInWallet, deposited
  } = data;
  const btcData = coins.find(item => item.coin === 'BTC');
  const cfxData = coins.find(item => item.coin === 'CFX');

  const electricityCost = 0.1246514 / USDCAD * total.power / 1000 * 24 * 1.1;
  const profitPerDay = total.speed * rewards.eth / 100 * ETHUSD
    + total.rvnSpeed * rewards.rvn / 100 * RVNUSD
    - electricityCost;

  const investment = investmentCad / USDCAD;

  const cash = cashInWallet / USDCAD;
  const eth = ethInWallet + total.balance;
  const btc = btcInWallet;
  const rvn = total.rvnPoolBalance;
  const totalBalance = cash + eth * ETHUSD + btc * btcData.price + rvn * RVNUSD + cfxInWallet * cfxData.price
    + usdtInWallet + busdInWallet + usdcInWallet + deposited / USDCAD;

  const roiPast24 = (investment - totalBalance) / (total.past24HoursIncome - electricityCost);
  const roiRealtime = (investment - totalBalance) / profitPerDay;

  return (
    <Paper className={classes.paper}>
      <Typography variant={"h6"} className={classes.cardTitle}>
        投资
      </Typography>
      <Divider/>
      <Grid container spacing={2}>
        <Tooltip title={"仅算已安装设备"}>
          <Grid item>
            总投资 <br/>
            <Typography variant={"h6"}>
              {formatCurrency(investment)}
            </Typography>
          </Grid>
        </Tooltip>
        {/*<Grid item>*/}
        {/*  回本时间(实际) <br/>*/}
        {/*  <Typography variant={"h6"}>*/}
        {/*    {roiPast24.toFixed(1)}天 / {(roiPast24 / 30).toFixed(1)}月*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}
        {/*<Grid item>*/}
        {/*  回本时间(实时) <br/>*/}
        {/*  <Typography variant={"h6"}>*/}
        {/*    {roiRealtime.toFixed(1)}天 / {(roiRealtime / 30).toFixed(1)}月*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}
      </Grid>
      <Typography variant={"caption"}>
        仅算已安装设备
        {/*(实际：使用过去24小时预估收益计算， 实时：使用实时收益计算)*/}
      </Typography>
    </Paper>
  )
}


export function NewInvestment({data, classes}) {
  const {total, USDCAD} = data;

  const electricityCost = 0.1246514 / USDCAD * total.power / 1000 * 24 * 1.1;

  return (
    <Paper className={classes.paper}>
      <Typography variant={"h6"} className={classes.cardTitle}>
        挖显卡
        <Typography variant={"caption"}>
          {' '}(使用实际收益计算)
        </Typography>
      </Typography>
      <Divider/>
      <Grid container spacing={3}>
        <Grid item>
          RTX3060Ti
          <br/>
          <Typography variant={"subtitle2"}>
            {formatCurrency(734.5 / USDCAD)}
          </Typography>
          <Typography variant={"h6"}>
            {(734.5 / USDCAD / (total.past24HoursIncome - electricityCost)).toFixed(1)}天
          </Typography>
        </Grid>
        <Grid item>
          RTX3070<br/>
          <Typography variant={"subtitle2"}>
            {formatCurrency(1017 / USDCAD)}
          </Typography>
          <Typography variant={"h6"}>
            {(1017 / USDCAD / (total.past24HoursIncome - electricityCost)).toFixed(1)}天
          </Typography>
        </Grid>
        <Grid item>
          RTX3080 <br/>
          <Typography variant={"subtitle2"}>
            {formatCurrency(1356 / USDCAD)}
          </Typography>
          <Typography variant={"h6"}>
            {(1356 / USDCAD / (total.past24HoursIncome - electricityCost)).toFixed(1)}天
          </Typography>
        </Grid>
      </Grid>
      <Typography variant={"caption"}>

      </Typography>
    </Paper>
  )
}

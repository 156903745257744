import { DataGrid } from "@material-ui/data-grid";
import React from "react";

const currenciesColumn = [
  {field: 'name', headerName: 'Name', width: 100},
  {field: 'price', headerName: 'Price', width: 140},
];

export function CurrencyExchange({data}) {

  return (
    <DataGrid
      rows={data}
      columns={currenciesColumn}
      disableExtendRowFullWidth
      disableSelectionOnClick
      disableColumnSelector
      hideFooter
      disableColumnFilter
      disableColumnMenu
      density={"compact"}
      autoHeight
    />
  )
}


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React, { useState } from "react";

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AppBar, Toolbar, Typography, Switch, Box, CssBaseline, Hidden } from '@material-ui/core';
import { DarkMode, LightMode } from '@material-ui/icons';

import {CurrencyPicker} from './pages/components/CurrencyPicker';
import {setDisplayCurrency} from './pages/formatter'
import Dashboard from './pages/Dashboard';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [settings, setSettings] = useState({
    theme: localStorage.getItem('theme') || (prefersDarkMode ? 'dark' : 'light'),
    currency:  localStorage.getItem('currency') || 'USD',
  });

  setDisplayCurrency(settings.currency);

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          mode: settings.theme,
        }
      }),
    [settings.theme],
  );

  /**
   * Set theme.
   * @param {"dark"|"light"} mode
   */
  const setTheme = (mode) => {
    localStorage.setItem('theme', mode);
    setSettings(state => ({...state, theme: mode}));
  }

  const isDarkMode = settings.theme === 'dark';

  const onChangeCurrency = (currency) => {
    localStorage.setItem('currency', currency);
    setDisplayCurrency(currency);
    setSettings(state => ({...state, currency}))
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <React.Fragment>
        <AppBar position="absolute" color={"primary"} sx={{backgroundColor: isDarkMode && '#424242'}}>
          <Toolbar variant={"dense"}>
            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
              <Hidden smDown>
                Mining Stats
              </Hidden>
            </Typography>

            <CurrencyPicker currency={settings.currency} onChange={onChangeCurrency}/>
            <div>
              <Box sx={{display: 'flex', alignItems: 'center'}} color="inherit" onClick={() => setTheme(settings.theme === 'dark' ? 'light' : 'dark')}>
                <LightMode/>
                <Switch checked={settings.theme === 'dark'}/>
                <DarkMode/>
              </Box>
            </div>

          </Toolbar>
        </AppBar>
        <Box sx={{paddingTop: 6}}/>

        <Dashboard displayCurrency={settings.currency}/>

      </React.Fragment>
    </ThemeProvider>
  )

}

export default App;

import * as React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Hidden} from "@material-ui/core";

const Currency2Name = {
  USD: 'USD 美金',
  CAD: 'CAD 加币',
  CNY: 'CNY 人民币',
  USDC: 'USDC 代币'
}

export function CurrencyPicker({currency, onChange}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = currency => () => {
    setAnchorEl(null);
    onChange(currency)
  };

  return (
    <div style={{paddingRight: 15}}>
      <Button
        variant={"outlined"}
        color={"inherit"}
        onClick={handleClick}
      >
        <Hidden smDown>
          显示汇率：
        </Hidden>
        {Currency2Name[currency]}
      </Button>
      <Menu
        id="demo-positioned-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose(currency)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose('USD')}>{Currency2Name.USD}</MenuItem>
        <MenuItem onClick={handleClose('CAD')}>{Currency2Name.CAD}</MenuItem>
        <MenuItem onClick={handleClose('CNY')}>{Currency2Name.CNY}</MenuItem>
        <MenuItem onClick={handleClose('USDC')}>{Currency2Name.USDC}</MenuItem>
      </Menu>
    </div>
  );
}

import {Divider, FormControlLabel, Grid, Paper, Switch, Tooltip, Typography, useTheme} from "@material-ui/core";
import React, {useState} from "react";
import {formatCurrency} from './formatter';
import {Link} from "./components/Link";


export function UniswapEarning({data, classes}) {
  const {
    USDCAD, USDCNY, uniswap, USDCUSD
  } = data;

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [simplified, setSimplified] = useState(true);

  const fragments = [];

  const toggle = <Grid item xs={12} xl={10} key="toggole-lp">
    <FormControlLabel control={<Switch checked={!simplified}/>} label="显示流动性挖矿细节" sx={{color: isDarkMode && 'white'}}
                      onClick={() => setSimplified(!simplified)}/>
  </Grid>

  for (const position of uniswap.positions) {
    const {
      currentToken0Price, currentToken1Price, currentToken0Amount, currentToken1Amount,
      collectedFeesToken0, collectedFeesToken1, uncollectedFeesToken0, uncollectedFeesToken1,
      depositTimestamp, depositedToken0, depositedToken1, token0Symbol, token1Symbol, positionId, feeTier,
      amountDepositedUSD, amountCollectedUSD, amountWithdrawnUSD, priceLowerInverted, priceUpperInverted
    } = position;
    const totalBalance = (currentToken0Amount * currentToken0Price + currentToken1Amount * currentToken1Price) * USDCUSD;

    const positionFees = (Number(collectedFeesToken0) + Number(uncollectedFeesToken0)) * currentToken0Price
      + (Number(collectedFeesToken1) + Number(uncollectedFeesToken1)) * currentToken1Price;

    const days = (Date.now() / 1000 - Number(depositTimestamp)) / 3600 / 24;

    // Assume all collected tokens go back to this position.
    const historyToken0Amount = Number(depositedToken0);
    const historyToken1Amount = Number(depositedToken1);

    const depositedUSD = Number(amountDepositedUSD) - Number(amountWithdrawnUSD);
    const withdrawUSD = Number(amountWithdrawnUSD);
    const delta = totalBalance - depositedUSD;


    fragments.push(
      <Grid item xs={12} sm={6} md={6} lg={"auto"} id={positionId}>
        <Paper className={classes.paper}>
          <Typography variant={"h6"} className={classes.cardTitle}>
            <Link
              href={`/uniswap/polygon/positions/${positionId}`}>
              {priceLowerInverted.toFixed(2)} ~ {priceUpperInverted.toFixed(2)} {' '}
              {token0Symbol}/{token1Symbol} {feeTier / 10000}%
            </Link>
          </Typography>
          <Divider/>
          <Grid container spacing={2}>
            {!simplified && <Grid item>
              起始总额 <br/>
              <Tooltip title={`CAD ${(depositedUSD * USDCAD).toFixed(2)} / RMB ${(depositedUSD * USDCNY).toFixed(2)}`}
                       enterTouchDelay={0}>
                <Typography variant={"h6"}>
                  {formatCurrency(depositedUSD)}
                </Typography>
              </Tooltip>
            </Grid>}
            <Grid item>
              当前总额 <br/>
              <Tooltip title={`CAD ${(totalBalance * USDCAD).toFixed(2)} / RMB ${(totalBalance * USDCNY).toFixed(2)}`}
                       enterTouchDelay={0}>
                <Typography variant={"h6"}>
                  {formatCurrency(totalBalance)}
                </Typography>
              </Tooltip>
            </Grid>
            {!simplified && <Grid item>
              提出总额 <br/>
              <Tooltip title={`CAD ${(withdrawUSD * USDCAD).toFixed(2)} / RMB ${(withdrawUSD * USDCNY).toFixed(2)}`}
                       enterTouchDelay={0}>
                <Typography variant={"h6"}>
                  {formatCurrency(withdrawUSD)}
                </Typography>
              </Tooltip>
            </Grid>}
            <Grid item>
              总收益 <br/>
              <Typography variant={"h6"}>
                {formatCurrency(delta + positionFees)}
                {` (${((delta + positionFees) / depositedUSD * 100).toFixed(2)}%)`}
              </Typography>
            </Grid>
            {!simplified && <Grid item>
              {token0Symbol} <br/>
              <Typography variant={"h6"}>
                {formatCurrency(currentToken0Amount * currentToken0Price * USDCUSD)}
              </Typography>
            </Grid>}
            {!simplified && <Grid item>
              {token1Symbol} <br/>
              <Typography variant={"h6"}>
                {formatCurrency(currentToken1Amount * currentToken1Price * USDCUSD)}
              </Typography>
            </Grid>}
            {!simplified && <Grid item>
              时间 <br/>
              <Typography variant={"h6"}>
                {days.toFixed(2)}天
              </Typography>
            </Grid>}
            {!simplified && <Grid item>
              手续费收益 <br/>
              <Typography variant={"h6"}>
                {formatCurrency(positionFees) + ' '}
                ({((positionFees / depositedUSD * 100)).toFixed(2)}%)
              </Typography>
            </Grid>}
            {!simplified && <Grid item>
              手续费收益/天 <br/>
              <Typography variant={"h6"}>
                {formatCurrency(positionFees / days) + ' '}
                ({((positionFees / depositedUSD * 100) / days).toFixed(2)}%)
              </Typography>
            </Grid>}
            {!simplified && <Grid item>
              手续费收益/月<br/>
              <Typography variant={"h6"}>
                {formatCurrency((positionFees) / days * 30) + ' '}
                ({((positionFees / depositedUSD * 100) / days * 30).toFixed(2)}%)
              </Typography>
            </Grid>}
            <Grid item>
              总收益/月 <br/>
              <Typography variant={"h6"}>
                {formatCurrency((delta + positionFees) / days * 30) + ' '}
                ({(((delta + positionFees) / depositedUSD * 100) / days * 30).toFixed(2)}%)
              </Typography>
            </Grid>
          </Grid>
          <Typography variant={"caption"}>
            {token0Symbol}: {historyToken0Amount.toPrecision(6)} -> {Number(currentToken0Amount).toPrecision(6)}, {' '}
            {token1Symbol}: {historyToken1Amount.toPrecision(6)} -> {Number(currentToken1Amount).toPrecision(6)}
          </Typography>
        </Paper>
      </Grid>
    )

  }
  return <>
    {toggle}
    <>
      {fragments}
    </>
  </>;
}

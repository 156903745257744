import { Divider, Grid, Paper, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { formatHashrate } from './formatter';


export function Speed({data, classes}) {
  const {total} = data;

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const luck = (total.effectiveSpeed / total.speed * 100);


  return (
    <Paper className={classes.paper}>
      <Typography variant={"h6"} className={classes.cardTitle}>
        ETH速度
      </Typography>
      <Divider/>
      <Grid container spacing={2}>
        <Grid item>
          实时 <br/>
          <Typography variant={"h6"}>
            {formatHashrate(total.speed)}
          </Typography>
        </Grid>
        <Grid item>
          有效 <br/>
          <Typography variant={"h6"}>
            {formatHashrate(total.effectiveSpeed)}
          </Typography>
        </Grid>
        <Grid item>
          幸运度 <br/>
          <Typography variant={"h6"}>
           <span style={{color: luck >= 97 ? (isDarkMode ? '#afedaf' : 'green') : 'red'}}>
            {luck.toFixed(2)}%
            </span>
          </Typography>
        </Grid>
      </Grid>
      <Typography variant={"caption"}>
        (RVN实时速度: {formatHashrate(total.rvnSpeed)})
      </Typography>
    </Paper>
  )
}

export function formatHashrate(data, fraction) {
  if (!data)
    return '-- Mh/s'
  const number = Number(data);
  if (number > 1e12)
    return `${(number / 1e12).toFixed(fraction || 3)} Eh/s`
  else if (number > 1e9)
    return `${(number / 1e9).toFixed(fraction || 3)} Ph/s`
  else if (number > 1e6)
    return `${(number / 1e6).toFixed(fraction || 3)} Th/s`
  else if (number > 1e3)
    return `${(number / 1e3).toFixed(fraction == null ? 3 : fraction)} Gh/s`
  else
    return `${Number(data).toFixed(fraction == null ? 2 : fraction)} Mh/s`
}

export function formatDifficulty(data) {
  if (!data)
    return 0;
  const number = Number(data);
  if (number > 1e12)
    return `${(number / 1e12).toFixed(6)}E`
  else if (number > 1e9)
    return `${(number / 1e9).toFixed(6)}P`
  else if (number > 1e6)
    return `${(number / 1e6).toFixed(6)}T`
  else if (number > 1e3)
    return `${(number / 1e3).toFixed(6)}G`
  else
    return `${Number(data).toFixed(6)}M`
}


export function formatTemperature(data) {
  return `${Number(data).toFixed(0)} °C`
}

export function formatTime(date) {
  if (!date || isNaN(new Date(date).getMonth())) return '--';

  const seconds = (Date.now() - new Date(date)) / 1000;
  if (seconds < 60)
    return `${seconds.toFixed(0)}秒`
  else if (seconds < 60 * 60)
    return `${(seconds / 60).toFixed(1)}分钟`
  else
    return `${(seconds / 3600).toFixed(2)}小时`;
}

// Currency Related
export let displayCurrency = 'USD';

export function setDisplayCurrency(val) {
  displayCurrency = val;
}

let currencyData = {};

export function setCurrencyData(data) {
  currencyData = data;
}

export function formatCurrency(value) {
  if (displayCurrency === 'USD') {
    const nf = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      currency: 'USD',
      currencyDisplay: "narrowSymbol",
      style: "currency"
    });
    return `${nf.format(value.toFixed(2))}`
  } else if (displayCurrency === 'CAD') {
    const nf = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      currency: 'CAD',
      currencyDisplay: "symbol",
      style: "currency"
    });
    return `${nf.format((value * currencyData.USDCAD).toFixed(2))}`
  } else if (displayCurrency === 'CNY') {
    const nf = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      currency: 'CNY',
      currencyDisplay: "narrowSymbol",
      style: "currency"
    });
    return `${nf.format((value * currencyData.USDCNY).toFixed(2))}`
  } else if (displayCurrency === 'USDC') {
    const nf = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      currency: 'USD',
      currencyDisplay: "narrowSymbol",
      style: "currency"
    });
    return `${nf.format((value * currencyData.USDUSDC).toFixed(2))}`
  }
}



import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { formatCurrency } from './formatter';


export function Misc({data, classes}) {
  const {rewards, ETHUSD, coins} = data;
  const btcData = coins.find(item => item.coin === 'BTC');
  const cfxData = coins.find(item => item.coin === 'CFX');
  const [ethPrice, setEthPrice] = useState(0);

  useEffect(() => {
    const socket = new WebSocket('wss://stream.binance.com:9443/ws');
    let lastUpdate = Date.now() - 500;

    socket.addEventListener('open', function (event) {
      console.log('Connected to binance API!');
      socket.send(JSON.stringify({
        "method": "SUBSCRIBE",
        "params":
          [
            "ethusdt@aggTrade",
          ],
        "id": 1
      }));
    });

    // Listen for messages
    socket.addEventListener('message', function (event) {
      // console.log('Message from server ', event.data);
      if (Date.now() - lastUpdate > 200) {
        if (event.data.match(/aggTrade/)) {
          const data = event.data.match(/"p":"(.+)",/)[1];
          setEthPrice(parseFloat(data));
        }

        lastUpdate = Date.now();
      }

    });

    // Listen for possible errors
    socket.addEventListener('error', function (event) {
      console.log('WebSocket error: ', event);
    });

    return () => {
      socket.send(JSON.stringify({
        "method": "UNSUBSCRIBE",
        "params":
          [
            "ethusdt@aggTrade",
          ],
        "id": 1
      }));
      socket.close();
    }
  }, []);

  return (
    <Paper className={classes.paper}>
      <Typography variant={"h6"} className={classes.cardTitle}>
        ETH相关
      </Typography>
      <Divider/>
      <Grid container spacing={2}>
        <Grid item>
          奖励 <br/>
          <Typography variant={"h6"}>
            {(Number(rewards.eth)).toFixed(8)}
          </Typography>
        </Grid>
        <Grid item>
          ETH价格 <br/>
          <Typography variant={"h6"}>
            {formatCurrency(ethPrice)}
          </Typography>
        </Grid>
        <Grid item>
          BTC价格 <br/>
          <Typography variant={"h6"}>
            {formatCurrency(btcData.price)}
          </Typography>
        </Grid>
        <Grid item>
          CFX价格 <br/>
          <Typography variant={"h6"}>
            {formatCurrency(cfxData.price)}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant={"caption"}>
        (奖励单位：ETH/Day, 100Mh/s)
      </Typography>
    </Paper>
  )
}

import {Divider, Grid, Paper, Tooltip, Typography} from "@material-ui/core";
import React from "react";
import {formatCurrency} from './formatter';


export function RealtimeEarning({data, classes}) {
  const {total, rewards, ETHUSD, USDCAD, RVNUSD} = data;

  const electricityCost = 0.1246514 / USDCAD * total.power / 1000 * 24 * 1.1;
  const profitPerDay = total.speed * rewards.eth / 100 * ETHUSD
    + total.rvnSpeed * rewards.rvn / 100 * RVNUSD
    - electricityCost;


  return (
    <Paper className={classes.paper}>
      <Typography variant={"h6"} className={classes.cardTitle}>
        实时收益
      </Typography>
      <Divider/>
      <Grid container spacing={2}>
        <Grid item>
          每天 <br/>
          <Typography variant={"h6"}>
            {formatCurrency(profitPerDay)}
          </Typography>
        </Grid>
        <Grid item>
          每30天 <br/>
          <Typography variant={"h6"}>
            {formatCurrency((profitPerDay) * 30)}
          </Typography>
        </Grid>
        <Grid item>
          每年 <br/>
          <Typography variant={"h6"}>
            {formatCurrency((profitPerDay) * 365)}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant={"caption"}>
        (电费已扣除, ETH+RVN)
      </Typography>
    </Paper>
  )
}

export function Past24Earning({data, classes}) {
  const {total, USDCAD} = data;

  const electricityCost = 0.1246514 / USDCAD * total.power / 1000 * 24 * 1.1;

  return (
    <Paper className={classes.paper}>
      <Typography variant={"h6"} className={classes.cardTitle}>
        过去24小时预估收益
      </Typography>
      <Divider/>
      <Grid container spacing={2}>
        <Grid item>
          每天 <br/>
          <Typography variant={"h6"}>
            {formatCurrency(total.past24HoursIncome - electricityCost)}
          </Typography>
        </Grid>
        <Grid item>
          每30天 <br/>
          <Typography variant={"h6"}>
            {formatCurrency((total.past24HoursIncome - electricityCost) * 30)}
          </Typography>
        </Grid>
        <Grid item>
          每年 <br/>
          <Typography variant={"h6"}>
            {formatCurrency((total.past24HoursIncome - electricityCost) * 365)}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant={"caption"}>
        (电费已扣除, ETH+RVN)
      </Typography>
    </Paper>
  )
}


export function TotalEarning({data, classes}) {
  const {
    total,
    coins,
    USDCAD,
    USDCNY,
    USDCUSD,
    cashInWallet,
    withdrawn,
    uniswap,
    wallets,
    currenciesData
  } = data;


  const tokens = {};

  for (const [walletProvider, wallet] of Object.entries(wallets)) {
    // if (walletProvider)
    for (const [tokenSymbol, amount] of Object.entries(wallet)) {

      // special case for uniswap
      if (typeof amount !== "number" || tokenSymbol === 'CAD') continue;

      if (!tokens[tokenSymbol]) {
        let price;
        if (walletProvider === 'uniswap') {
          price = uniswap.prices[tokenSymbol] * USDCUSD || 0;
        } else {
          const currCoinInfo = coins.find(item => item.coin === tokenSymbol);
          price = currCoinInfo?.price || currenciesData[tokenSymbol.toLowerCase()] || 0;
        }
        tokens[tokenSymbol] = {balance: 0, price};
      }
      tokens[tokenSymbol].balance += amount;
    }
  }
  // combine weth with eth
  tokens.ETH.balance += tokens.WETH.balance + total.balance;
  if (tokens.WETH.price)
    tokens.ETH.price = tokens.WETH.price;
  delete tokens.WETH;

  const cash = cashInWallet / USDCAD;
  let totalBalance = cash + withdrawn / USDCAD;

  for (const tokenData of Object.values(tokens)) {
    totalBalance += tokenData.balance * tokenData.price;
  }

  console.log(tokens)
  const getTokenBalanceInUSD = (tokenSymbol) => {
    if (!tokens[tokenSymbol]) return 0;
    return tokens[tokenSymbol].balance * tokens[tokenSymbol].price;
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant={"h6"} className={classes.cardTitle}>
        所有收入
      </Typography>
      <Divider/>
      <Grid container spacing={2}>
        <Grid item>
          全部 <br/>
          <Tooltip title={`CAD ${(totalBalance * USDCAD).toFixed(2)} / RMB ${(totalBalance * USDCNY).toFixed(2)}`}
                   enterTouchDelay={0}>
            <Typography variant={"h6"}>
              {formatCurrency(totalBalance)}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item>
          现金(CAD) <br/>
          <Typography variant={"h6"}>
            {formatCurrency(cash)}
          </Typography>
        </Grid>
        <Grid item>
          ETH <br/>
          <Tooltip title={`${tokens.ETH.balance}(Wallet) + ${total.balance.toFixed(6)}(Pool)`} enterTouchDelay={0}>
            <Typography variant={"h6"}>
              {formatCurrency(getTokenBalanceInUSD('ETH'))}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item>
          CFX <br/>
          <Typography variant={"h6"}>
            {formatCurrency(getTokenBalanceInUSD('CFX'))}
          </Typography>
        </Grid>
        <Grid item>
          USD代币 <br/>
          <Typography variant={"h6"}>
            {formatCurrency(getTokenBalanceInUSD('USDC') + getTokenBalanceInUSD('USDT') + getTokenBalanceInUSD('BUSD'))}
          </Typography>
        </Grid>
        <Grid item>
          已提出 <br/>
          <Tooltip title={`CAD ${(withdrawn).toFixed(2)} / RMB ${(withdrawn / USDCAD * USDCNY).toFixed(2)}`}
                   enterTouchDelay={0}>
            <Typography variant={"h6"}>
              {formatCurrency(withdrawn / USDCAD)}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
      <Typography variant={"caption"}>
        {Object.entries(tokens).filter(([_, data]) => {
          return data.balance > 0;
        }).map(([tokenSymbol, data]) => {
          return `${tokenSymbol}: ${Number(data.balance.toPrecision(8))}`
        }).join(', ')}
      </Typography>
    </Paper>
  )
}

const BASE_URL = 'https://mining.lesterlyu.com/api';
// const BASE_URL = 'http://localhost:3666/api';


export async function getCoins() {
  const data = (await fetch(`${BASE_URL}/coins`)).json();
  return data;
}

export async function getMiners() {
  const data = (await fetch(`${BASE_URL}/miners`)).json();
  return data;
}

export async function getCurrencies() {
  const data = (await fetch(`${BASE_URL}/currencies`)).json();
  return data;
}

export async function getPools() {
  const data = (await fetch(`${BASE_URL}/pools`)).json();
  return data;
}

export async function getWalletBalances() {
  const data = (await fetch(`${BASE_URL}/balances`)).json();
  return data;
}

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Container, CssBaseline, Link
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { io } from "socket.io-client";

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  table: {
    minWidth: 650,
  },
}));

const theme = createMuiTheme({
  palette: {
    mode: 'light',
  }
});

const rows = [
  {
    store: 'CC',
    worker: '1',
    title: 'asus-tuf-gaming-nvidia-geforce-rtx-3080-10gb-gddr6x-video-card',
    model: 'RTX3080',
    price: 1199.99,
    quantity: 0,
    time: new Date('4/16/2021 18:30'),
    url: 'https://www.bestbuy.ca/en-ca/product/asus-tuf-gaming-nvidia-geforce-rtx-3080-10gb-gddr6x-video-card/14953248',
    internalCode: '14953248',
    notes: 'SoldOutOnline',
  }
];

export default function StockChecker() {
  const classes = useStyles();

  const [data, setData] = useState({});

  useEffect(() => {
    const socket = io("http://64.52.29.188:5678", {
      reconnectionDelayMax: 10000,
      autoConnect: true,
      auth: {
        type: 1,
      }
    });
    socket.connect();

    socket.on('connect', () => {
      console.log('Socket connected!');
    });

    socket.on('update', data => {
      setData(prev => {
        const {name, store} = data;
        const key = store + name;

        if (name.match(/3090/)) {
          data.gpu = '3090';
        } else if (name.match(/3080/)) {
          data.gpu = '3080';
        } else if (name.match(/3070/)) {
          data.gpu = '3070';
        } else if (name.match(/(3060 ti)|(3060-ti)/i)) {
          data.gpu = '3060Ti';
        } else if (name.match(/3060/)) {
          data.gpu = '3060';
        } else if (name.match(/1660/)) {
          data.gpu = '1660(S)';
        }

        prev[key] = data;
        return {...prev};
      })
    });

    return () => socket.disconnect();

  }, []);

  const cellStyle = {
    padding: '0px 14px 0px 14px',
    fontSize: 11,
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <div className={classes.container}>
        <Container>
          {/*<Typography variant="h5">?</Typography>*/}
          <TableContainer component={Paper}>
            <Table stickyHeader className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>GPU</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell>time</TableCell>
                  {/*<TableCell align="right">Internal Code</TableCell>*/}
                  <TableCell align="right">Worker</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(data).map((row) => (
                  <TableRow hover key={row.store + row.name} style={{height: 12}}>
                    <TableCell style={cellStyle}>
                      {row.gpu}
                    </TableCell>
                    <TableCell style={cellStyle}>
                      <Link target="_blank" href={row.url}>{row.name.slice(0, 50)}...</Link>
                    </TableCell>
                    <TableCell style={cellStyle}>{row.price}</TableCell>
                    <TableCell style={cellStyle}>{row.quantity || 0}</TableCell>
                    <TableCell style={cellStyle}>{row.note}</TableCell>
                    <TableCell style={cellStyle}>{row.time && new Date(row.time).toLocaleTimeString()}</TableCell>
                    {/*<TableCell align="right">{row.internalCode}</TableCell>*/}
                    <TableCell align="right" style={cellStyle}>{row.store}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </div>

    </ThemeProvider>
  );

}

import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { formatCurrency, formatHashrate } from './formatter';
import { DataGrid } from "@material-ui/data-grid";

const poolColumn = [
  {field: 'name', headerName: 'Name', width: 180},
  {field: 'value', headerName: 'Value', width: 140},
]

export function F2PoolStat({data, classes}) {
  const {ETHUSD} = data;
  const {f2poolEth} = data.pools;

  const f2poolData = [
    {name: '余额 (ETH)', value: f2poolEth.balance.toFixed(8)},
    {name: '余额', value: formatCurrency(f2poolEth.balance * ETHUSD)},
    {name: '今日收入', value: formatCurrency(f2poolEth.value_today * ETHUSD)},
    {name: '过去24小时预估收入', value: formatCurrency(f2poolEth.value_last_day * ETHUSD)},
    {name: '有效速度 (过去24小时)', value: formatHashrate(f2poolEth.hashes_last_day)},
    {name: '有效速度 (过去1小时)', value: formatHashrate(f2poolEth.hashes_last_hour)},
    {name: '有效速度 (实时)', value: formatHashrate(f2poolEth.hashrate)},
    {name: '本地速度', value: formatHashrate(f2poolEth.local_hash / 1e6)},
  ];
  f2poolData.forEach((row, idx) => row.id = idx);

  return (
    <Paper className={classes.paper}>
      <div className={classes.title}>ETH矿池：F2Pool</div>
      <div>
        <DataGrid
          rows={f2poolData}
          columns={poolColumn}
          disableExtendRowFullWidth
          disableSelectionOnClick
          disableColumnSelector
          hideFooter
          disableColumnFilter
          disableColumnMenu
          density={"compact"}
          headerHeight={0}
          autoHeight
        />
      </div>
    </Paper>
  )
}


export function EtherminePoolStat({data, classes}) {
  const {ETHUSD} = data;
  const {dashboard, currentStats} = data.pools.ethermine;

  const hashratesLastHour = [], hashratesLastDay = [];
  dashboard.statistics.forEach(stat => {
    // Within 1 hour from current time
    if ((Date.now() - stat.time * 1000) <= 1000 * 600 * 6) {
      hashratesLastHour.push(stat.currentHashrate);
    }

    if ((Date.now() - stat.time * 1000) <= 1000 * 600 * 6 * 24) {
      hashratesLastDay.push(stat.currentHashrate);
    }
  });
  const avgHashRateLastHour = hashratesLastHour.reduce((a, b) => a + b, 0) / hashratesLastHour.length / 1e6;
  const avgHashRateLastDay = hashratesLastDay.reduce((a, b) => a + b, 0) / hashratesLastDay.length / 1e6;

  const unpaid = dashboard.currentStatistics.unpaid;

  const ethermineDate = [
    {name: '余额 (ETH)', value: (unpaid / 1e18).toFixed(8)},
    {name: '余额', value: formatCurrency((unpaid / 1e18) * ETHUSD)},
    {name: '过去24小时预估收入', value: formatCurrency(currentStats.coinsPerMin * 60 * 24 * ETHUSD)},
    {name: '有效速度 (过去24小时)', value: formatHashrate(avgHashRateLastDay)},
    {name: '有效速度 (过去1小时)', value: formatHashrate(avgHashRateLastHour)},
    {name: '有效速度 (实时)', value: formatHashrate(currentStats.currentHashrate / 1e6)},
    {name: '本地速度', value: formatHashrate(currentStats.reportedHashrate / 1e6)},
  ];
  ethermineDate.forEach((row, idx) => row.id = idx);
  return (
    <Paper className={classes.paper}>
      <div className={classes.title}>ETH矿池：Ethermine</div>
      <div>
        <DataGrid
          rows={ethermineDate}
          columns={poolColumn}
          disableExtendRowFullWidth
          disableSelectionOnClick
          disableColumnSelector
          hideFooter
          disableColumnFilter
          disableColumnMenu
          density={"compact"}
          headerHeight={0}
          autoHeight
        />
      </div>
    </Paper>
  )
}


export function RavencoinFlypoolStat({data, classes}) {
  const {RVNUSD} = data;
  const {dashboard, currentStats} = data.pools.ravencoinFlypool;

  const hashratesLastHour = [], hashratesLastDay = [];
  dashboard.statistics.forEach(stat => {
    // Within 1 hour from current time
    if ((Date.now() - stat.time * 1000) <= 1000 * 600 * 6) {
      hashratesLastHour.push(stat.currentHashrate);
    }

    if ((Date.now() - stat.time * 1000) <= 1000 * 600 * 6 * 24) {
      hashratesLastDay.push(stat.currentHashrate);
    }
  });
  const avgHashRateLastHour = hashratesLastHour.reduce((a, b) => a + b, 0) / hashratesLastHour.length / 1e6;
  const avgHashRateLastDay = hashratesLastDay.reduce((a, b) => a + b, 0) / hashratesLastDay.length / 1e6;

  const unpaid = dashboard.currentStatistics.unpaid;

  const ethermineDate = [
    {name: '余额 (RVN)', value: (unpaid / 1e8).toFixed(8)},
    {name: '余额', value: formatCurrency((unpaid / 1e8) * RVNUSD)},
    {name: '过去24小时预估收入', value: formatCurrency(currentStats.coinsPerMin * 60 * 24 * RVNUSD)},
    {name: '有效速度 (过去24小时)', value: formatHashrate(avgHashRateLastDay)},
    {name: '有效速度 (过去1小时)', value: formatHashrate(avgHashRateLastHour)},
    {name: '有效速度 (实时)', value: formatHashrate(currentStats.currentHashrate / 1e6)},
    {name: '本地速度', value: formatHashrate(currentStats.reportedHashrate / 1e6)},
  ];
  ethermineDate.forEach((row, idx) => row.id = idx);
  return (
    <Paper className={classes.paper}>
      <div className={classes.title}>RVN矿池：Flypool</div>
      <div>
        <DataGrid
          rows={ethermineDate}
          columns={poolColumn}
          disableExtendRowFullWidth
          disableSelectionOnClick
          disableColumnSelector
          hideFooter
          disableColumnFilter
          disableColumnMenu
          density={"compact"}
          headerHeight={0}
          autoHeight
        />
      </div>
    </Paper>
  )
}


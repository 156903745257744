import React from "react";

import { Chart } from "react-charts";
import { Paper, Typography, useTheme } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';

export function MyChart({data, classes}) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const chartData = React.useMemo(
    () => [
      {
        label: 'Hashrate (速度)',
        data: Object.entries(data.hashrate).map(([date, value]) => ({
          primary: new Date(date), secondary: value / 1e6
        })).filter(({secondary}) => secondary > 0).sort((a, b) => {
            return a.primary - b.primary
        }),
      },
    ], [data]);

  const axes = React.useMemo(
    () => [
      {
        primary: true, type: 'time', position: 'bottom',
        hardMin: Number(new Date(Object.keys(data.hashrate)[0])),
        hardMax: Date.now(),
      },
      {
        type: 'linear', position: 'left', min: 0, max: 1000, base: 0,
        format: val => isSmall ? val : `${val} Mh/s`, show: !isSmall
      },
    ], [data.hashrate, isSmall]);

  return (
    <Paper className={classes.paper}>
      <Typography variant={"h6"} className={classes.cardTitle}>
        历史速度
      </Typography>
      <div style={{padding: 3}}/>
      <div style={{
        minHeight: '230px',
        minWidth: '250px'
      }}>
        <Chart data={chartData} axes={axes} primaryCursor secondaryCursor dark={isDarkMode}/>
      </div>

    </Paper>
  )
}

import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { formatCurrency } from './formatter';


export function Power({data, classes}) {
  const {total, USDCAD} = data;


  const electricityCost = 0.1246514 / USDCAD * total.power / 1000 * 24 * 1.1;

  return (
    <Paper className={classes.paper}>
      <Typography variant={"h6"} className={classes.cardTitle}>
        功耗/电费
      </Typography>
      <Divider/>
      <Grid container spacing={3}>
        <Grid item>
          功耗 <br/>
          <Typography variant={"h6"}>
            {total.power && total.power.toFixed(2)}W
          </Typography>
        </Grid>
        <Grid item>
          电费/度<br/>
          <Typography variant={"h6"}>
            {formatCurrency(0.1246514 / USDCAD)}
          </Typography>
        </Grid>
        <Grid item>
          电费/天<br/>
          <Typography variant={"h6"}>
            {formatCurrency(electricityCost)}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant={"caption"}>
        (电源转换损耗为10%)
      </Typography>
    </Paper>
  )
}

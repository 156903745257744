import React from "react";
import {CircularProgress, Typography, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
    marginTop: 50,
  }
}));

export function Loading({message = 'Loading Components...', className}) {
  const classes = useStyles();
  return (
    <div style={{textAlign: 'center'}} className={className}>
      <CircularProgress className={classes.progress}/>
      <Typography variant="subtitle2" color={"textSecondary"}>
        {message}
      </Typography>
    </div>
  );
}

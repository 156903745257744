import React, {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Paper, useMediaQuery, useTheme} from "@material-ui/core";
import {DataGrid} from '@material-ui/data-grid';

import {Loading} from './components/Loading';
import {getCoins, getCurrencies, getMiners, getPools, getWalletBalances} from "../apis";
import {CurrencyExchange} from "./CurrencyExchange";
import {Speed} from './Speed';
import {Power} from './Power';
import {Past24Earning, RealtimeEarning, TotalEarning} from './Earning';
import {Investment, NewInvestment} from './Investment';
import {Misc} from './Misc';
import {MyChart} from './Chart.js';
import {EtherminePoolStat, F2PoolStat, RavencoinFlypoolStat} from "./Pools";
import {
  formatCurrency, formatHashrate, setCurrencyData, formatDifficulty
} from './formatter';
import {Miners} from "./Miners";
import {UniswapEarning} from "./Uniswap";


const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    transition: 'background-color 0.4s'
  },
  title: {
    paddingBottom: 5,
  },
  cardTitle: {
    color: theme.palette.mode === 'dark' && '#fff'
  },
  paper: {
    padding: 15,
  },
  tooltip: {
    textDecoration: 'underline',
  },

  gpuName: {
    color: theme.palette.mode === 'dark' ? '#ccf' : '#0250a0'
  },
  gpuSpeed: {
    color: theme.palette.mode === 'dark' ? '#11eec3' : theme.palette.text.primary
  },
  gpuProfit: {
    color: theme.palette.mode === 'dark' ? '#ffda7e' : theme.palette.text.primary
  }
}));


const coinsColumn = [
  {field: 'coin', headerName: 'Coin', width: 90},
  {field: 'price', headerName: 'Price', width: 140, valueFormatter: ({value}) => value ? formatCurrency(value) : 'N/A'},
  {field: 'name', headerName: 'Name', width: 130},
  {field: 'algorithm', headerName: 'Algorithm', width: 130},
  {
    field: 'network_hashrate',
    headerName: 'Network Hashrate',
    width: 150,
    valueFormatter: ({value}) => formatHashrate(value / 1e6)
  },
  {
    field: 'volume',
    headerName: 'Volume',
    width: 180,
    valueFormatter: ({value}) => value ? formatCurrency(value) : 'N/A'
  },
  {field: 'reward', headerName: 'Reward', width: 200, valueFormatter: ({value}) => value},
  {
    field: 'reward_block',
    headerName: 'Reward Block',
    width: 200,
    valueFormatter: ({value, row}) => `${value} ${row.reward_unit}`
  },
  {field: 'difficulty', headerName: 'Difficulty', width: 200, valueFormatter: ({value}) => formatDifficulty(value)},
]

// Total + 3060 + Asus Tuf 3080 x 2 + EVGA 3080
const INVESTMENT_CAD = 5681 + 631.67 + 1115.05 * 2 + 1299.4887
  // evga 3070 + strix 3070
  + (859.99 + 939) * 1.13
  // tuf 3060 * 2
  + (579.99 * 2) * 1.13
  // X99-WS + E5-2603 v3 x2 + HX1000 + RAM + Cooler + Pcie x8 riser x2 + Dummy Display
  + 260 + 100 + 350 + 81.35 + 74.66 + 29.96 * 2 + 34.46
  // 20 Risers + Pcie 1 to 4 x4 + Thermal Pads
  + 200 + 200
  // AMD RX6800
  + 817.43
  // BB 3060ti FE + 3070 FE
  + 1389.88;

function Dashboard({displayCurrency}) {
  const theme = useTheme();
  // const isDarkMode = theme.palette.mode === 'dark';
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();

  const [values, setValues] = useState({
    cashInWallet: null,
    ethInWallet: null,
    investmentCad: INVESTMENT_CAD,
    currencies: [],
    coins: [],
    USDCAD: null,
    USDCNY: null,
    ETHUSD: null,
    RVNUSD: null,
    USDCUSD: null, // usdc to usd or usd per usdc
    USDTUSD: null, // usdt to usd or usd per usdt
    pools: {f2poolEth: {}, ethermine: {}, ravencoinFlypool: {}},
    miners: {},
    total: {},
    rewards: {},
    charts: {},
    wallets: {}
  });

  const {currencies, coins, miners} = values;

  useEffect(() => {
    async function update() {
      // do not update if page is not visible
      if (document.visibilityState !== 'visible') return;

      const coinsData = await getCoins();
      const ethData = coinsData.find(item => item.coin === 'ETH');
      const ETHUSD = ethData.price;

      const rvnData = coinsData.find(item => item.coin === 'RVN');
      const RVNUSD = rvnData.price;

      const rewards = {};
      rewards.eth = ethData.reward * 100000000 * 24;
      rewards.rvn = rvnData.reward * 100000000 * 24;

      const currenciesData = await getCurrencies();
      const currencies = [
        {id: 1, name: 'USD-CAD', price: currenciesData.cad},
        {id: 2, name: 'USD-CNY', price: currenciesData.cny},
        {id: 3, name: 'CAD-CNY', price: (currenciesData.cny / currenciesData.cad).toFixed(5)},
        {id: 4, name: 'CAD-USD', price: (1 / currenciesData.cad).toFixed(5)},
        // {id: 5, name: 'CNY-CAD', price: (currenciesData.cad / currenciesData.cny).toFixed(5)},
        // {id: 6, name: 'CNY-USD', price: (1 / currenciesData.cny).toFixed(5)}
      ]

      const wallets = await getWalletBalances();

      let total = {speed: 0, effectiveSpeed: 0, power: 0};
      const charts = {};
      const poolsData = await getPools();
      const f2poolEth = poolsData.f2poolEth;
      const ethermine = poolsData.ethermine;
      const ravencoinFlypool = poolsData.ravencoinFlypool;

      if (typeof ravencoinFlypool.currentStats === "string") {
        ravencoinFlypool.currentStats = {
          coinsPerMin: 0,
          currentHashrate: 0,
          reportedHashrate: 0,
        }
      }

      charts.hashrate = f2poolEth.hashrate_history;

      for (const stat of ethermine.dashboard.statistics) {
        const key = new Date(stat.time * 1000).toISOString().replace(/\.\d\d\d/, '');
        if (charts.hashrate[key])
          charts.hashrate[key] += stat.currentHashrate;
        else// if (Date.now() - stat.time * 1000 > 1000 * 600) // greater than 10 minutes from current time.
          charts.hashrate[key] = stat.currentHashrate;
      }

      if (typeof ethermine.currentStats === "string") {
        ethermine.currentStats = {
          ...ethermine.dashboard.currentStatistics,
          coinsPerMin: 0
        }
      }

      total.past24HoursIncome = (f2poolEth.value_last_day + ethermine.currentStats.coinsPerMin * 60 * 24) * ETHUSD
        + ravencoinFlypool.currentStats.coinsPerMin * 60 * 24 * RVNUSD;

      // ETH pool balance
      total.balance = f2poolEth.balance + ethermine.dashboard.currentStatistics.unpaid / 1e18;

      // RVN Stats
      total.rvnPoolBalance = ravencoinFlypool.dashboard.currentStatistics.unpaid / 1e8;
      total.rvnSpeed = ravencoinFlypool.currentStats.currentHashrate / 1e6;

      // CFX

      const minersData = await getMiners();
      for (const [minerName, data] of Object.entries(minersData)) {
        // compatibility
        if (!Array.isArray(data.stats)) data.stats = Object.values(data.stats);

        // Skip rig that is not active, mark the rig is offline
        if (Date.now() - new Date(data.reportTime) > 1000 * 60) {
          data.isOffline = true;
          data.stats.forEach((gpu, idx) => {
            gpu.id = idx;
          });
          continue;
        }

        let speed = 0, effectiveSpeed = 0, power = 0;
        data.stats.forEach((gpu, idx) => {
          gpu.id = idx;

          // skip 0 speed gpus
          if (!gpu.speed) return;

          speed += Number(gpu.speed);
          effectiveSpeed += Number(gpu.effectiveSpeed);
          power += Number(gpu.gpu_power_usage);

          const electricityCost = 0.1246514 / currenciesData.cad * gpu.gpu_power_usage / 1000 * 24 * 1.1;
          gpu.profitPerDay = gpu.speed * rewards.eth / 100 * ETHUSD - electricityCost;

          gpu.name = gpu.name.replace(/ super/i, 'S').replace(/geforce /i, '');
          gpu.speed = Number(gpu.speed);
          gpu.effectiveSpeed = Number(gpu.effectiveSpeed);

        });

        minersData[minerName].speed = speed;
        minersData[minerName].effectiveSpeed = effectiveSpeed;
        minersData[minerName].power = power;
        total.speed += speed;
        total.effectiveSpeed += effectiveSpeed;
        total.power += power;
      }

      setCurrencyData({
        USDCAD: Number(currenciesData.cad),
        USDCNY: Number(currenciesData.cny),
        USDUSDC: 1 / Number(currenciesData.usdc),
      });
      setValues({
        wallets,
        cashInWallet: wallets.newton.CAD || 0,
        ethInWallet: (wallets.newton.ETH || 0) + (wallets.binance.ETH || 0) + (wallets.native.ETH || 0),
        btcInWallet: (wallets.newton.BTC || 0) + (wallets.binance.BTC || 0),
        cfxInWallet: (wallets.native.CFX || 0) + (wallets.binance.CFX || 0),
        etcInWallet: wallets.binance.ETC || 0,
        busdInWallet: (wallets.binance.BUSD || 0) + (wallets.native.BUSD || 0),
        usdtInWallet: (wallets.newton.USDT || 0) + (wallets.binance.USDT || 0),
        usdcInWallet: (wallets.newton.USDC || 0) + (wallets.binance.USDC || 0) + (wallets.native.USDC || 0),
        uniswap: wallets.uniswap,
        investmentCad: INVESTMENT_CAD,

        currencies, currenciesData,
        coins: coinsData,
        USDCAD: Number(currenciesData.cad),
        USDCNY: Number(currenciesData.cny),
        ETHUSD, RVNUSD,
        USDCUSD: 1 / Number(currenciesData.usdc),
        USDTUSD: Number(currenciesData.usdt),
        BUSDUSD: Number(currenciesData.busd),
        pools: poolsData,
        miners: minersData,
        total: total,
        rewards: rewards,
        charts,
        withdrawn: 2905.79 + 2502.82 + 2976.07 + 4669.20,
      });
    }

    update();
    const task = setInterval(update, 10000);

    return () => clearInterval(task);
  }, []);

  useEffect(() => {
    setValues(values => {
      if (values.miners)
        for (const data of Object.values(values.miners)) {
          data.stats = [...data.stats];
        }
      if (values.coins)
        values.coins = [...values.coins];
      return {...values};
    })
  }, [displayCurrency]);

  if (currencies.length === 0)
    return <Loading message={"Loading data..."} className={classes.root}/>;


  return (
    <React.Fragment>

      <Grid container className={classes.root} spacing={2} justifyContent="center">
        <Grid item xs={12} xl={10}>
          <Grid container spacing={smUp ? 2 : 1}>
            <Grid item xs={12} sm={6} md={3} lg={"auto"}>
              <Speed data={values} classes={classes}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={"auto"}>
              <Power data={values} classes={classes}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={"auto"}>
              <Misc data={values} classes={classes}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={"auto"}>
              <RealtimeEarning data={values} classes={classes}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={"auto"}>
              <Past24Earning data={values} classes={classes}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={"auto"}>
              <TotalEarning data={values} classes={classes}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={"auto"}>
              <Investment data={values} classes={classes}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={"auto"}>
              <NewInvestment data={values} classes={classes}/>
            </Grid>
            <UniswapEarning data={values} classes={classes}/>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MyChart data={values.charts} classes={classes}/>
            </Grid>
          </Grid>
        </Grid>

        <Miners miners={miners}/>

        <Grid item xs={12} xl={10}>
          <Grid container spacing={smUp ? 2 : 1}>

            <Grid item xs={12} lg={3}>
              <F2PoolStat classes={classes} data={values}/>
            </Grid>
            <Grid item xs={12} lg={3}>
              <EtherminePoolStat classes={classes} data={values}/>
            </Grid>
            <Grid item xs={12} lg={3}>
              <RavencoinFlypoolStat classes={classes} data={values}/>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Paper className={classes.paper}>
                <div className={classes.title}>Currencies Exchange</div>
                <div>
                  <CurrencyExchange data={currencies}/>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Paper className={classes.paper}>
                <div className={classes.title}>Coins Data</div>
                <div style={{height: 254}}>
                  <DataGrid
                    rows={coins}
                    columns={coinsColumn}
                    disableExtendRowFullWidth
                    disableSelectionOnClick
                    disableColumnSelector
                    hideFooter
                    disableColumnFilter
                    disableColumnMenu
                    density={"compact"}
                  />
                </div>
              </Paper>
            </Grid>

          </Grid>
        </Grid>
      </Grid>

    </React.Fragment>
  );
}

export default Dashboard;

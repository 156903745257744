import React, {Suspense} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import MiningStats from './App';
import StockChecker from "./pages/StockChecker";
import {Loading} from "./pages/components/Loading";

const UniswapApp = React.lazy(() => import('./pages/Uniswap/App'));

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/stock">
          <StockChecker/>
        </Route>
        <Route path="/uniswap">
          <Suspense fallback={<Loading/>}>
            <UniswapApp/>
          </Suspense>
        </Route>
        <Route path="/">
          <MiningStats/>
        </Route>
      </Switch>
    </Router>
  );
}

import { formatCurrency, formatHashrate, formatTemperature, formatTime } from "./formatter";
import React, { useState } from "react";
import AnimatedNumber from "react-animated-number";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  useTheme,
  FormControlLabel,
  Switch,
  Box
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";


const useStyles = makeStyles(theme => ({

  title: {
    paddingBottom: 5,
  },
  paper: {
    padding: 15,
  },
  gpuName: {
    color: theme.palette.mode === 'dark' ? '#ccf' : '#0250a0'
  },
  gpuSpeed: {
    color: theme.palette.mode === 'dark' ? '#11eec3' : theme.palette.text.primary
  },
  gpuProfit: {
    color: theme.palette.mode === 'dark' ? '#ffda7e' : theme.palette.text.primary
  },
}));


function GPUName({value}) {
  const classes = useStyles();
  return <span className={classes.gpuName}>{value}</span>
}

function GPUSpeed({value}) {
  const classes = useStyles();
  return <AnimatedNumber component="text" value={value}
                         style={{
                           transition: '0.8s ease-out',
                           // fontSize: 48,
                           transitionProperty:
                             'background-color, color, opacity'
                         }}
                         className={classes.gpuSpeed}
                         duration={600}
                         formatValue={n => formatHashrate(n)}/>
}

function GPUProfit({value}) {
  const classes = useStyles();
  return <span className={classes.gpuProfit}>{value ? formatCurrency(value) : '--'}</span>
}

function SubmitTime({value, row}) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  if (!value) return '尚未提交';
  else if (row.id !== 'total')
    return `${formatTime(value)}前`
  else
    return (
      <span style={{
        color: ((Date.now() - new Date(value) > 1000 * 60 * 10)
          ? 'red' : (isDarkMode ? '#afedaf' : 'green'))
      }}>
      {formatTime(value)}前
    </span>
    )
}

const minerColumn = [
  {
    field: 'name', headerName: '显卡', width: 170,
    renderCell: function ({value}) {
      return <GPUName value={value}/>
    }
  },
  {
    field: 'coin', headerName: '挖', width: 60,
  },
  {
    field: 'speed', headerName: '速度', width: 120,
    renderCell: ({value}) => <GPUSpeed value={value}/>
  },
  {
    field: 'effectiveSpeed', headerName: '有效速度', width: 120,
    renderCell: ({value}) => <GPUSpeed value={value}/>
  },
  {
    field: 'profitPerDay', headerName: '实时收益/天', width: 110,
    renderCell: ({value}) => <GPUProfit value={value}/>
  },
  {
    field: 'lastShare', headerName: '上次提交时间', width: 130, renderCell: ({value, row}) => {
      return <SubmitTime value={value} row={row}/>;
    }
  },
  {
    field: 'valid', headerName: '正确/延迟/错误', width: 130, valueFormatter: ({value, row}) => {
      return `${value} / ${row.stale} / ${row.invalid}`;
    }
  },
  {
    field: 'gpu_power_usage', headerName: '功耗', width: 100, valueFormatter: ({value}) => {
      return value && value.toFixed(2) + 'W';
    }
  },
  {
    field: 'gpu_temp', headerName: 'GPU / HotSpot / VRAM', width: 180,
    valueFormatter: ({value, row}) => {
      if (value == null) return '';
      const gpu = value ? formatTemperature(value) : 'N/A';
      const hotspot = row.__hotspot_temp ? formatTemperature(row.__hotspot_temp) : 'N/A';

      const vram_temp = row.__gddr6x_temp || row.__vram_temp;
      const vram = vram_temp ? formatTemperature(vram_temp) : 'N/A';
      return `${gpu} / ${hotspot} / ${vram}`;
    }
  },
  {
    field: 'gpu_fan_speed', headerName: '风扇速度(转速)', width: 210,
    valueFormatter: ({value, row}) => {
      if (value == null) return '';
      return `${value}% (${row.fans && row.fans.map(fan => (fan.current_rpm || '? ') + 'rpm').join(', ')})`;
    }
  },
  {
    field: 'gpu_clock_core', headerName: '核心频率', width: 170, valueFormatter: ({value, row}) => {
      if (!row.oc_data && value) return `${value} Mhz`;
      if (!row.oc_data) return '';

      if (row.oc_data.core_clock_delta)
        return `${value}Mhz (+${row.oc_data.core_clock_delta})`
      else
        return `${value}Mhz (Max ${row.oc_data.core_clock_limit})`
    }
  },
  {
    field: 'gpu_clock_memory', headerName: '显存频率', width: 160, valueFormatter: ({value, row}) => {
      if (!row.oc_data && value) return `${value} Mhz`;
      if (!row.oc_data) return '';
      return `${value}Mhz (+${row.oc_data.memory_clock_delta})`
    }
  },
  {
    field: 'too_hot', headerName: '过热', width: 70, valueFormatter: ({value}) => {
      if (value == null) return '';
      return value ? '是' : '否'
    }
  },
];


export function Miners({miners}) {
  const classes = useStyles();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [simplified, setSimplified] = useState(true);
  const [enlarged, setEnlarged] = useState(null);

  const toggle = <Grid item xs={12} xl={10} key={"toggle"}>
    <FormControlLabel control={<Switch checked={!simplified}/>} label="显示矿机细节" sx={{color: isDarkMode && 'white'}}
                      onClick={() => setSimplified(!simplified)}/>
  </Grid>

  const handleMouseOver = (key) => () => {
    setEnlarged(key);
  }

  const handleMouseLeave = (key) => () => {
    setEnlarged(null);
  }

  if (simplified) {

    const component = Object.entries(miners).map(([minerName, data]) => {
      // Skip rig has no gpu installed
      if (data.stats.length === 0)
        return null;

      const reportTime = new Date(data.reportTime);

      return <div key={minerName}>
        <Grid container spacing={0} justifyContent={"flex-start"} alignItems="flex-start" sx={{paddingBottom: 1}}>
          <Grid item xs={"auto"}>
            <Box sx={{paddingTop: '11px', paddingRight: 1, fontSize: 13, minWidth: 70}}>
              <span
                style={{fontSize: 14, color: data.isOffline && 'red'}}>
                {minerName} ({(data.isOffline ? `离线${formatTime(reportTime)}` : `${formatTime(reportTime)}前`)})
              </span> <br/>
              有效: {formatHashrate(data.effectiveSpeed, 0)} <br/>
              运行: {formatTime(data.started)}<br/>
            </Box>
          </Grid>

          <Grid item xs={"10"}>
            <Grid container>
              {data.stats.map(gpu => {
                const temp = formatTemperature(Math.max(gpu.gpu_temp, gpu.__hotspot_temp || 0, gpu.__gddr6x_temp || 0, gpu.__vram_temp || 0));
                const name = gpu.name.replace(/asus|evga|gigabyte|msi|rtx|gtx|geforce|rx/ig, '');

                const nameColor = isDarkMode ? '#c4c4ff' : '#0250a0'
                let tempColor = isDarkMode ? '#1eda1e' : '#60b160';
                let borderColor = isDarkMode ? '#1a9c1a' : '#60b160';

                const vram_temp = gpu.__gddr6x_temp || gpu.__vram_temp
                if (vram_temp) {
                  if (vram_temp > 105) {
                    tempColor = isDarkMode ? '#ff4343' : '#db2424';
                    borderColor = tempColor;
                  } else if (vram_temp > 90) {
                    tempColor = isDarkMode ? 'yellow' : '#a9a900';
                    borderColor = isDarkMode ? '#abab00' : '#a9a900';
                  }

                } else if (gpu.__hotspot_temp) {
                  if (gpu.__hotspot_temp > 85) {
                    tempColor = isDarkMode ? 'red' : '#db2424';
                    borderColor = tempColor;
                  } else if (gpu.__hotspot_temp > 78) {
                    tempColor = isDarkMode ? '#abab00' : '#a9a900';
                    borderColor = isDarkMode ? '#abab00' : '#a9a900';
                  }
                } else {
                  if (gpu.gpu_temp > 65) {
                    tempColor = isDarkMode ? 'red' : '#db2424';
                    borderColor = tempColor;
                  } else if (gpu.gpu_temp > 70) {
                    tempColor = isDarkMode ? '#abab00' : '#a9a900';
                    borderColor = isDarkMode ? '#abab00' : '#a9a900';
                  }
                }

                const getTemp = () => {
                  const gpuTemp = gpu.gpu_temp ? formatTemperature(gpu.gpu_temp) : 'N/A';
                  const hotspot = gpu.__hotspot_temp ? formatTemperature(gpu.__hotspot_temp) : 'N/A';

                  const vram_temp = gpu.__gddr6x_temp || gpu.__vram_temp
                  const vram = vram_temp ? formatTemperature(vram_temp) : 'N/A';
                  return `${gpuTemp} / ${hotspot} / ${vram}`;
                }

                const border = `2px solid ${borderColor}`;

                const key = minerName + gpu.device_id;
                const isEnlarged = enlarged === key;
                return (
                  <Grid item xs="auto" onMouseOver={handleMouseOver(key)}
                        onMouseLeave={handleMouseLeave(key)}
                        className={classes.gridItem} key={key}
                        sx={{
                          height: isEnlarged ? 134 : 80,
                          width: isEnlarged ? 134 : 80,
                          margin: isEnlarged ? '-25px' : '2px',
                          zIndex: isEnlarged ? 100 : 99,
                          border,
                          fontSize: 12,
                          backgroundColor: theme.palette.background.paper,
                          transition: 'all 0.15s ease',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          paddingTop: '0px !important',
                          paddingLeft: '2px !important',
                        }}>
                    {isEnlarged ? <>
                      <Box sx={{color: nameColor}}>
                        {gpu.name}
                      </Box>
                      <Box sx={{color: tempColor}}>
                        {getTemp()}
                      </Box>
                      <Box>
                        有效速度 / 速度: <br/>
                        {formatHashrate(gpu.effectiveSpeed, 2).slice(0, -4)} / {formatHashrate(gpu.speed, 2)}
                      </Box>
                      功耗: <span style={{color: isDarkMode ? '#f0ff33' : '#ff9b06'}}>
                    {(gpu.gpu_power_usage || 0).toFixed(2)}W
                  </span><br/>
                      风扇: <span style={{color: isDarkMode ? '#10ff30' : '#178a8a'}}>
                    {gpu.gpu_fan_speed} % <br/>
                  </span>
                      <Box>
                        {gpu.coin}收益/天: <GPUProfit value={gpu.profitPerDay}/>
                      </Box>
                    </> : <>
                      <Box sx={{color: nameColor}}>
                        {name}
                      </Box>
                      <Box sx={{color: tempColor}}>
                        {((gpu.__gddr6x_temp || gpu._vram_temp) === temp) ? '显存 ' : (gpu.__hotspot_temp ? '热点 ' : '核心 ')}{temp}
                      </Box>
                      {gpu.coin && gpu.coin + ':'}<br/>
                      {gpu.effectiveSpeed && gpu.effectiveSpeed.toFixed(0) + '/' + formatHashrate(gpu.speed, 0)}
                    </>
                    }
                  </Grid>
                )
              })}
            </Grid>
          </Grid>


        </Grid>
      </div>
    });

    return [
      toggle,
      <Grid item xs={12} xl={10} id={"paper"}>
        <Paper className={classes.paper}>
          {component}
        </Paper>
      </Grid>
    ]
  }

  const details = Object.entries(miners).map(([minerName, data]) => {
    const total = {
      id: 'total',
      name: '总计 (' + formatTime(data.started) + ')',
      speed: data.speed,
      effectiveSpeed: data.effectiveSpeed,
      gpu_power_usage: data.power,
      lastShare: data.lastAccepted,
      profitPerDay: data.stats.reduce((prev, curr) => prev + (curr.profitPerDay ? curr.profitPerDay : 0), 0),
      valid: data.stats.reduce((prev, curr) => prev + (curr.valid ? Number(curr.valid) : 0), 0),
      stale: data.stats.reduce((prev, curr) => prev + (curr.stale ? Number(curr.stale) : 0), 0),
      invalid: data.stats.reduce((prev, curr) => prev + (curr.invalid ? Number(curr.invalid) : 0), 0),
    };

    const reportTime = new Date(data.reportTime);

    return <Grid key={minerName} item sm={12} sx={{paddingBottom: 2}}>
      <Paper className={classes.paper}>
        <Typography sx={{paddingBottom: '2px', paddingLeft: 1}}>
          矿机：{minerName}  ({(data.isOffline ? `离线${formatTime(reportTime)}` : `${formatTime(reportTime)}前更新`)})
        </Typography>
        <div style={{minWidth: '100%'}}>
          <DataGrid
            rows={[...data.stats, total]}
            columns={minerColumn}
            disableExtendRowFullWidth
            disableSelectionOnClick
            disableColumnSelector
            hideFooter
            disableColumnFilter
            disableColumnMenu
            autoHeight
            density={"compact"}
            rowHeight={40}
          />
        </div>
      </Paper>
    </Grid>
  })

  return [
    toggle,
    <Grid item xs={12} xl={10} id={"paper"}>
      {/*<Grid container spacing={2}>*/}
      {details}
      {/*</Grid>*/}

    </Grid>
  ];
}
